import React, { useEffect, useReducer, useState } from "react";
import $ from 'jquery';
import { projectReducer, initialState } from "./projectReducer";
import { useSelector } from "react-redux";
import RootLoader from "../Loader/RootLoader";
import MainTaskChatBox from "../ChatMainTask";
import MainTaskInfo from "../TasksModals/mainTaskInfo";
import MainTaskVerify from "../TaskVerify/mainTaskVerify";
import { Link } from "react-router-dom";
import ModifyMainTask from "../TasksModals/modifyMainTask";
import AddSubTask from "../SubTaskModals/addSubTask";
import MainTaskDelete from "../TasksModals/deleteMainTask";
import { getSubStringId } from "../SubStringConvert";
// import { role_array } from "../getDefaultRoles";
import { useWebProperties } from "../webProperties";
import convertPSTtoLocalTime from "../convertPSTtoLocalTime";
import { getUserStories } from "./Services/getUserStories";
import { getUserstoriesMessages } from "./Services/getUserstoriesMessages";
import ChangeStatus from "../../UserModule/Backlogs/changeStatus";
import moment from "moment";
import TaskInfo from "../../UserModule/UserDashboard/taskInfo";
// import Moment from 'moment';

export default function ProjectMainTasks(props) {
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(projectReducer, initialState)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState();
    const { SCRUM_MASTER, PRODUCT_OWNER, MAINTASKNAME, VERIFY, ACTION, DELETE, VIEW_DETAILS, ACCEPTANCE_CRITERIA,
        MAINTASKPROGRESS, ASSIGNED_BY, REASSIGN, VIEWSUBTASKS, NEWSUBTASK, EDIT, ASSIGNED_DATE, CHANGE_STATUS, WORKFLOW, COMMENTS, WFT,
        ASSIGNED_TO, STORY_POINTS, CHAT, MAINTASK_DESCRIPTION, ACTION_ICON, TARGET_DATE, role_array, PROJECT_COMPLETED_DATE, WORKFLOW_ENABLE } = useWebProperties();
    useEffect(() => {
        getUserStories(dispatch, getUser.user, props.data.id);
        getUserstoriesMessages(dispatch, getUser.user);
        // eslint-disable-next-line
    }, [props.data])
    useEffect(() => {
        if (state.manageTasks.length >= 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                    // ordering:false,
                })
            })
        }
        //  eslint-disable-next-line 
    }, [state.manageTasks])

    var date = moment();
    var currentDate = date.format('YYYY-MM-D');
    const pst = currentDate.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });
    const handleOpen = (action, index, sno, us_id) => {
        var info
        setOpen({ status: true, index: index, action: action });
        if (action === "addSubtask") {
            info = {
                mainTaskId: index.story_id,
                action: action,
                moduleId: index.sprint_id,
                ideaId: index.epic_id,
                title: index.story_title,
                assignedTo: index.assigned_to,
                subTasksAssignedTo: index.assigned_to,
                targetDate: index.target_date,
                us_id: index.us_id,
                template_story_id: index.template_story_id,
                is_workflow: index.is_workflow,
            }
        } else if (action === "modify") {
            info = {
                projectName: index.epic_name,

                moduleId: index.sprint_id,
                ideaId: index.epic_id,
                title: index.story_title,
                description: index.story_desc,
                taskId: index.story_id,
                acceptanceCriteria: index.acceptance_criteria,
                storyPoints: index.story_points,
                userDetails: index.assignedto,
                id: index.assigned_to,
                priorityLevel: index.priority_level,
                targetDate: index.target_date,
                action: action,
                sprintTargetDate: index.sprintTargetDate,
                us_id: index.us_id,
                template_story_id: index.template_story_id,
                is_workflow: index.is_workflow,
                currentDate: currentDate,


            }
        } else if (action === "Verify") {
            info = {
                us_id: index.us_id,
                mainTaskId: index.story_id, title: index.story_title, description: index.story_desc, action: action, moduleId: index.sprint_id
            }
        }
        else if (action === "Complete") {
            info = {
                us_id: index.us_id,
                mainTaskId: index.story_id, title: index.story_title, description: index.story_desc, action: action, moduleId: index.sprint_id
            }
        }
        // else if (action === "taskInfo") {
        //     info = {
        //         projectName: index.epic_name,
        //         title: index.story_title,
        //         description: index.story_desc,
        //         taskId: index.story_id,
        //         targetDate: index.targetDate,
        //         timeLeft: index.timeLeft,
        //         extraHours: index.extraHours,
        //         status: index.complete_status
        //     }
        // }
        else if (action === "taskInfo") {
            var view_status = "taskInfo"
            info = {
                view: view_status,
                projectName: index.epic_name,
                moduleName: index.sprint_desc,
                title: index.story_title,
                description: index.story_desc,
                taskId: index.story_id,
                createdDate: index.assigned_date,
                storyPoints: index.story_points,
                acceptanceCriteria: index.acceptance_criteria,
                // targetDate: index.targettime,
                // timeLeft: index.timeLeft,
                // extraHours: index.extraHours,
                taskProgress: index.story_status,
                assignedTo: index.assignedto,
                assignedBy: index.assignedby,
                targetDate: index.target_date,
                us_id: index.us_id,
                completedStatus: index.complete_status,
                completedDate: index.task_end_date,
                main_task_id: index.main_task_id,
            }

        }
        else if (action === "reassign") {
            info = {
                projectName: index.epic_name,
                moduleId: index.sprint_id,
                ideaId: index.epic_id,
                title: index.story_title,
                description: index.story_desc,
                taskId: index.story_id,
                acceptanceCriteria: index.acceptance_criteria,
                storyPoints: index.story_points,
                userDetails: index.assignedto,
                id: index.assigned_to,
                priorityLevel: index.priority_level,
                action: action,
                us_id: index.us_id

            }
        }
        else if (action === "Delete") {
            info = {
                projectName: index.epic_name,
                title: index.story_title,
                description: index.story_desc,
                taskId: index.story_id,
                moduleId: index.sprint_id,
                action: action,
                us_id: index.us_id

            }
        } else if (action === "changeStatus" || action === "commentInfo") {
            info = {
                id: index.story_id,
                us_id: index.us_id,
                title: index.story_title,
                action: action,
                taskId: index.story_id,
                activeStatus: index.active_status,
                completeStatus: index.complete_status,
                sprintId: index.sprint_id
            }
        } else {

            info = {
                action: action, id: index.us_id, sno: sno, project_name: getUser.user.corp, project_id: index.project_id, main_task_id: index.main_task_id,
                story_type: index.story_type, us_id: index.us_id, group_task_type: index.group_task_type
            }
        }
        setCardInfo(info)
    };
    const handleClose = () => {

        setOpen({ status: false, index: 0 });
        getUserStories(props.data.dispatch, getUser.user, props.data.id);
        // getUserstoriesMessages(dispatch, getUser.user);
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }
    const getMessagesCount = (data, msg, task, empId, us_id) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task.id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return (
            <i>
                {/* {msgCount.length > 0 ? msgCount.length : null} */}
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }} onClick={(event) => handleOpen("maintask", data, msgCount, us_id)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginLeft: "-2px" }}>{msgCount.length > 9 ? "+9" : msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }} onClick={(event) => handleOpen("maintask", data, msgCount, us_id)} />

                        </div>
                }
            </i>
        )
    }

    const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);

    return <div className="table-responsive arc">
        {state.isLoading ? <RootLoader /> :
            <table
                search="true"
                id="example" className="table table-striped table-bordered"
                data-pagination="true"
            >
                <thead style={{ backgroundColor: '#F4FAF7' }}>
                    <tr>
                        <th>S.No</th>
                        <th>{MAINTASKNAME}</th>
                        {/* <th>Project Name</th> */}
                        {/* <th>{MAINTASK_DESCRIPTION}</th>
                        <th>{ASSIGNED_TO}</th>
                        <th>{ASSIGNED_BY}</th>
                        <th>{TARGET_DATE}</th>
                        <th>{MAINTASKPROGRESS}</th> */}
                        {/* <th>Time Left</th> */}
                        {/* <th>{STATUS}</th> */}
                        {/* {(getUser.user.role === "Scrum Master" || getUser.user.role === "Product Owner") ? <th>Verify</th> : null} */}
                        <th>{CHAT}</th>
                        <th>{COMMENTS}</th>
                        <th>{ACTION}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        state.manageTasks.length > 0 ? state.manageTasks.filter(item => {
                            return item.main_task_id === '0' || item.main_task_id === null
                        }).map((tasks, index) => {
                            return (
                                <tr key={index}>
                                    <td data-th="S.NO" style={{ width: '6px' }}>{index + 1}</td>
                                    {/* eslint-disable-next-line */}
                                    <td style={{ cursor: 'pointer' }} bgcolor={(tasks.currenttime > tasks.target_date && tasks.complete_status === 'pending' && tasks.target_date !== '0000-00-00') ? '#f58484' : null} onClick={() => handleOpen("taskInfo", tasks)} data-toggle="tooltip" data-placement="left" title={
                                        `${MAINTASK_DESCRIPTION}            : ${tasks.story_desc}\n\n` +
                                        `${ASSIGNED_TO}           : ${tasks.assignedto}\n\n` +
                                        `${STORY_POINTS}            : ${tasks.story_points}\n\n` +
                                        `${ACCEPTANCE_CRITERIA}: ${tasks.acceptance_criteria}\n\n` +
                                        `${ASSIGNED_BY}           : ${tasks.assignedby}\n\n` +
                                        `${ASSIGNED_DATE}        : ${convertPSTtoLocalTime(tasks.assigned_date)}\n\n` +
                                        `${MAINTASKPROGRESS}        : ${tasks.story_status}%\n\n` +
                                        `${TARGET_DATE}        : ${tasks.target_date !== null ? tasks.target_date : ''}\n\n` +
                                        `${PROJECT_COMPLETED_DATE}        : ${convertPSTtoLocalTime(tasks.task_end_date)}`
                                    }

                                    >{tasks.complete_status === 'pending' ? <b>{getSubStringId(getUser.user.corp, 3)}-
                                        {tasks.main_task_id !== null && tasks.main_task_id !== "0" ? `${tasks.main_task_id}-` : ''}
                                        {tasks.us_id}{tasks.story_title}</b> : <del> <b>{getSubStringId(getUser.user.corp, 3)}-{" "}
                                            {tasks.main_task_id !== null && tasks.main_task_id !== "0" ? `${tasks.main_task_id}-` : ''}
                                            {tasks.us_id}{tasks.story_title}</b></del>}
                                        {tasks.complete_status === 'verified' ? <span title={'Verified by ' + tasks.modifiedby} style={{ backgroundColor: 'green', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom">Verified</span> : null}

                                        {tasks.story_type === 'group' ? (<span style={{ backgroundColor: 'rgb(40, 210, 252)', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom">GR {tasks.group_task_type !== null ? `${tasks.group_task_type.substring(0, 1)}A` : null}</span>) : null}


                                        {tasks.task_count > 0 && (<span style={{ backgroundColor: 'rgb(143, 0, 255)', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom" title={WORKFLOW}>{WFT}</span>)}
                                    </td>
                                    {/* <td style={{ textTransform: "capitalize" }}>{tasks.projectName}</td> */}
                                    {/* <td style={{ width: '300px', textTransform: "capitalize" }}>{tasks.story_desc}</td>
                                    <td style={{ textTransform: "capitalize" }}>{tasks.assignedto}</td>
                                    <td style={{ textTransform: "capitalize" }}>{tasks.assignedby}</td>
                                    <td style={{ width: '120px' }}>{tasks.targetDate}</td>
                                    <td style={{ textAlign: 'center' }}>{tasks.taskStatus}%</td> */}
                                    {/* {tasks.complete_status === "pending" ? <td style={{width:'100px'}}>{tasks.timeLeft}</td> : <td style={{width:'100px'}}>{tasks.extraHours}</td>} */}
                                    {/* <td style={{ textAlign: 'center' }}>
                                        {tasks.complete_status === "pending" ? <button className="badge badge-pill badge-danger border-0" style={{ width: '100px' }} onClick={(event) => handleOpen("taskInfo", index)}>Pending</button>
                                            : <button className="badge badge-pill badge-success border-0" style={{ width: '100px' }} onClick={(event) => handleOpen("taskInfo", index)}>Completed</button>}
                                    </td> */}
                                    {/* {(getUser.user.role === "Scrum Master" || getUser.user.role === "Product Owner") ? <td style={{ textAlign: 'center' }}>{tasks.complete_status === "pending" ? <span > --- </span> : (getUser.user.role === "Scrum Master" || getUser.user.role === "Product Owner") ? <button className="dropdown-item badge badge-pill badge-success text-center" style={{ backgroundColor: "green" }} onClick={(event) => handleOpen("Verify", index)}>{VERIFY}</button> : <span > --- </span>}</td> : null} */}
                                    <td data-th={CHAT} style={{ width: '20px', textAlign: 'center' }}>
                                        <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0", marginLeft: '15px' }} >
                                            {
                                                getMessagesCount(tasks, state.mainTaskMessages, tasks, getUser.user.empId, tasks.us_id)
                                            }
                                        </button>
                                    </td>
                                    <td style={{ width: '8px', textAlign: 'center' }}><button style={{ backgroundColor: 'transparent', border: "0", width: '5px', padding: "0" }} type="button" > <img src="images/common/comments.svg" alt="logo" style={{ width: '20px', height: '20px', borderRadius: 0 }} onClick={(event) => handleOpen("commentInfo", index)} /></button></td>
                                    <td data-th={ACTION} style={{ textAlign: 'center', width: '10px' }}>
                                        <div className="dropdown show">
                                            {/* eslint-disable-next-line */}
                                            <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0' }} />
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                {tasks.complete_status === "pending" ?
                                                    <div>
                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", tasks)}>{VIEW_DETAILS}</button>
                                                        {accessRole ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modify", tasks)}>{EDIT}</button> : null}
                                                        {(new Date(tasks.sprinttargetdate) >= new Date(pst) || tasks.kanban_status === "1") ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#5cb0bd", color: 'white' }} onClick={(event) => handleOpen("changeStatus", tasks)}>{CHANGE_STATUS}</button> : null}
                                                        {
                                                            WORKFLOW_ENABLE === '1' ?
                                                                <>
                                                                    {tasks.task_count > 0 && (<button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}>{getUser.user.role === "admin" ? <Link to={{ pathname: '/subTasks', state: { id: tasks.story_id, title: tasks.story_title, moduleId: tasks.sprint_id, ideaId: tasks.epic_id, subTasksAssignedTo: tasks.assigned_to, targetDate: tasks.target_date, project_id: tasks.project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link> :
                                                                        <Link to={{ pathname: '/viewSubTasks', state: { id: tasks.story_id, title: tasks.story_title, moduleId: tasks.sprint_id, ideaId: tasks.epic_id, subTasksAssignedTo: tasks.assigned_to, targetDate: tasks.target_date, us_id: tasks.us_id, template_story_id: tasks.template_story_id, is_workflow: tasks.is_workflow, completeStatus: tasks.complete_status, project_id: tasks.project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link>}</button>)}
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            WORKFLOW_ENABLE === '1' ?
                                                                tasks.story_type !== 'group' ?
                                                                    <>
                                                                        < button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", tasks)}>{NEWSUBTASK}</button>
                                                                    </>
                                                                    :
                                                                    null
                                                                :
                                                                null
                                                        }
                                                        {(tasks.story_type === 'group' && tasks.main_task_id === '0') && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewGroupTasks', state: { id: tasks.story_id, title: tasks.story_title, moduleId: tasks.sprint_id, ideaId: tasks.project_id, subTasksAssignedTo: tasks.assignedTo, us_id: tasks.us_id, template_story_id: tasks.template_story_id, is_workflow: tasks.is_workflow, completeStatus: tasks.status, project_id: tasks.project_id } }} style={{ color: 'white' }}>View Group task</Link></button>}

                                                        {(getUser.user.empId === tasks.assignedid) ? <button className="dropdown-item badge badge-pill badge-danger text-center" style={{ backgroundColor: '#ED7173', color: 'white' }} onClick={(event) => handleOpen("Delete", index)}>{DELETE}</button> : null}
                                                        {/* {(accessRole && (new Date(tasks.sprinttargetdate) >= new Date(pst) || tasks.kanban_status === "1")) ? <button className="dropdown-item badge badge-pill badge-success text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("Complete", index)}>Complete {MAINTASK}</button> : null}  -->GNK -->version 1.0.6.04 */}
                                                    </div> :

                                                    <div>

                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", tasks)}>{VIEW_DETAILS}</button>
                                                        {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E" ,color:'white'}} onClick={(event) => handleOpen("modify", index)}>{EDIT}</button> */}

                                                        {/* <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}>{getUser.user.role === "admin" ? <Link to={{ pathname: '/subTasks', state: { id: state.manageTasks[index].id, title: state.manageTasks[index].story_title, moduleId: state.manageTasks[index].moduleId, ideaId: state.manageTasks[index].epic_id, subTasksAssignedTo: state.manageTasks[index].assigned_to, targetDate:state.manageTasks[index].target_date } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link> :
                                                            <Link to={{ pathname: '/viewSubTasks', state: { id: state.manageTasks[index].id, title: state.manageTasks[index].story_title, moduleId: state.manageTasks[index].moduleId, ideaId: state.manageTasks[index].epic_id, subTasksAssignedTo: state.manageTasks[index].assigned_to, targetDate:state.manageTasks[index].target_date, targetDate:state.manageTasks[index].target_date } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link>}</button>
                                                        <button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", index)}>{NEWSUBTASK}</button> */}
                                                        {accessRole ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "orange", color: 'white' }} onClick={(event) => handleOpen("reassign", tasks)}>{REASSIGN}</button> : null}
                                                        {accessRole ? <button className="dropdown-item badge badge-pill badge-success text-center" style={{ backgroundColor: "#6BC2D3", color: 'white' }} onClick={(event) => handleOpen("Verify", tasks)}>{VERIFY}</button> : null}
                                                    </div>}
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            )
                        }) : null}
                    {
                        open.action === "maintask" ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "modify" ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "addSubtask" ? <AddSubTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "Delete" ? <MainTaskDelete open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "Verify" || open.action === "Complete" ? <MainTaskVerify open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "reassign" ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "changeStatus" ? <ChangeStatus open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {open.status && open.action === "commentInfo" ? (
                        <TaskInfo
                            open={open.status}
                            handleClose={handleModalClose}
                            data={cardInfo}
                            handleModalClose={handleModalClose}
                        />
                    ) : null}
                </tbody>
            </table>
        }
    </div >
}
