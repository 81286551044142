import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Client_URL, Server_URL } from './config';

const UserContext = createContext();
export const UserProperties = (props) => {
  const user = {};
  let userProperties = useSelector(state => state.landingReducer.properties);
  let webproperties = useSelector(state => state.landingReducer.webProperties);
  let featureProperties = useSelector(state => state.auth.user.features);

  /*Feature Permissions*/

  const workFlowEnabledFeature = featureProperties?.filter(feature => feature.feature_name === "Work flow")[0];
  const roadBlocksEnabledFeature = featureProperties?.filter(feature => feature.feature_name === "Roadblocks")[0];
  const supportEnabledFeature = featureProperties?.filter(feature => feature.feature_name === "Support")[0];
  const groupEnabledFeature = featureProperties?.filter(feature => feature.feature_name === "Group")[0];
  const aiEnabledFeature = featureProperties?.filter(feature => feature.feature_name === "AI Enabled")[0];
  const mpmEnabledFeature = featureProperties?.filter(feature => feature.feature_name === "MPM")[0];

  user.WORKFLOW_ENABLE = workFlowEnabledFeature ? workFlowEnabledFeature.permission_status : undefined;
  user.ROADBLOCK_ENABLE = roadBlocksEnabledFeature ? roadBlocksEnabledFeature.permission_status : undefined;
  user.SUPPORT_ENABLE = supportEnabledFeature ? supportEnabledFeature.permission_status : undefined;
  user.GROUP_ENABLE = groupEnabledFeature ? groupEnabledFeature.permission_status : undefined;
  user.AI_ENABLE = aiEnabledFeature ? aiEnabledFeature.permission_status : undefined;
  user.MPM_ENABLE = mpmEnabledFeature ? mpmEnabledFeature.permission_status : undefined;

  /*Projects*/

  user.PROJECT = `${userProperties.PROJECT}`
  user.PROJECTS = `${userProperties.PROJECT}s`
  user.PROJECT_DESCRIPTION = `Description`
  user.PROJECT_REQUESTED_BY = `Requested by`
  user.PROJECT_APPROVED_BY = `Approved By`
  user.PROJECT_APPROVAL_STATUS = `Approval Status`
  user.PROJECT_STATUS = `${userProperties.PROJECT} Status`
  user.MANAGEPROJECTS = `${userProperties.PROJECT}s`
  user.NEWPROJECT = `Add ${userProperties.PROJECT}`
  user.PROJECTNO = `${userProperties.PROJECT} Id`
  user.PROJECTNAME = `${userProperties.PROJECT} Name`
  user.PROJECT_SUCCESS = `${userProperties.PROJECT} Added Successfully`
  user.PROJECT_MODIFY = `${userProperties.PROJECT} Modified Successfully`
  user.PROJECT_APPROVE = `${userProperties.PROJECT} Approved Successfully`
  user.PROJECT_REJECT = `${userProperties.PROJECT} Is Rejected`
  user.PROJECT_DELETE = `${userProperties.PROJECT} Deleted`
  user.PROJECT_VERIFY = `${userProperties.PROJECT} Verified Successfully`
  user.PROJECT_CLOSE = `${userProperties.PROJECT} Closed Successfully`
  user.COMPLETED_PROJECTS = `${userProperties.COMPLETED_EPICS}`
  user.PROJECT_APPROVED_DATE = `Approved Date`
  user.PROJECT_COMPLETED_DATE = `Completed Date`
  user.PROJECT_REOPEN = `Reopen`
  user.COMPLETED_PROJECT = `Complete ${userProperties.PROJECT}`

  user.CALENDAR = `Calendar`
  user.SUPPORT = 'Ask help'
  user.LANGUAGE = 'Language'


  /*Modules*/
  user.MODULES = `${userProperties.MODULES}s`
  user.MODULE = `${userProperties.MODULES}`
  user.NEWMODULE = `Add ${userProperties.MODULES}`
  user.VIEWMODULES = `View ${userProperties.MODULES}`
  user.ADDMODULE = `Add ${userProperties.MODULES}`
  user.MODULENAME = `${userProperties.MODULES} Name`
  user.MODIFYMODULE = `Modify ${userProperties.MODULES}`
  user.MODULE_ADD = `${userProperties.MODULES} Added Successfully`
  user.MODULE_MODIFY = `${userProperties.MODULES} Modified Successfully`
  user.MODULE_DELETE = `${userProperties.MODULES} Deleted Successfully`
  user.ACTIVE_SPRINT = `Active ${userProperties.MODULES}`
  user.DELETE_SPRINT_ALERT = `A ${userProperties.MODULES} with completed ${userProperties.USER_STORIES} can not be deleted`;


  /*Maintasks */
  user.MAINTASKS = `${userProperties.USER_STORIES}`;
  user.MAINTASK = `${userProperties.MAINTASKS}`
  user.NEWMAINTASK = `Add ${userProperties.MAINTASKS}`;
  user.VIEWMAINTASKS = `View ${userProperties.MAINTASKS}`;
  user.MAINTASKID = `${userProperties.MAINTASKS} Id`
  user.MAINTASKNAME = `${userProperties.MAINTASKS} Name`
  user.MAINTASK_DESCRIPTION = `Description`
  user.MAINTASKPROGRESS = `${userProperties.MAINTASKS} Progress`
  user.MODIFYMAINTASK = `Modify ${userProperties.MAINTASKS}`
  user.MAINTASK_ADD = `${userProperties.MAINTASKS} added successfully`
  user.MAINTASK_MODIFY = `${userProperties.MAINTASKS} Modified Successfully`
  user.MAINTASK_REASSIGN = `${userProperties.MAINTASKS} Reassigned Successfully`
  user.MAINTASK_DELETE = `${userProperties.MAINTASKS} Delete Successfully`
  user.MAINTASK_VERIFY = `${userProperties.MAINTASKS} Verified Successfully`
  user.MAINTASK_VERIFY_FAIL = `You can not verify ${userProperties.MAINTASKS} untill ${userProperties.SUBTASKS}s are verified`
  user.ACTIVE_STORIES = `Active ${userProperties.USER_STORIES}` //user stories Count

  /*Subtasks*/
  user.SUBTASKS = `${userProperties.SUBTASKS}s`;
  user.SUBTASK = `${userProperties.SUBTASKS}`;
  user.KUDOS_TITLE = `Category`;
  user.PENDING_SUBTASKS = `${userProperties.BACKLOG}`
  user.SUBTASKID = `${userProperties.SUBTASKS} Id`
  user.SUBTASKTITLE = `${userProperties.SUBTASKS} Name`
  user.SUBTASK_DESCRIPTION = `${userProperties.SUBTASKS} Description`
  user.SUBTASK_PROGRESS = `${userProperties.SUBTASKS} Progress`
  user.NEWSUBTASK = `Add ${userProperties.SUBTASKS}`;
  user.VIEWSUBTASKS = `View ${userProperties.SUBTASKS}s`;
  user.MODIFYSUBTASK = `Modify ${userProperties.SUBTASKS}`
  user.SUBTASK_ADD = `${userProperties.SUBTASKS} Added Successfully`
  user.SUBTASK_MODIFY = `${userProperties.SUBTASKS} Modified Successfully`
  user.SUBTASK_DELETE = `${userProperties.SUBTASKS} Deleted Successfully`
  user.SUBTASK_VERIFY = `${userProperties.SUBTASKS} Verified Successfully`
  user.SUBTASK_ACTIVE = `${userProperties.SUBTASKS} Activated successfully`
  user.SUBTASK_COMPLETE = `${userProperties.SUBTASKS} Completed Successfully`


  // user.SUBTASK_VERIFY_FAIL = `you can not verify module untill subtasks are verified`


  user.WORKFLOW_TEMPLATE = `Workflow Template`
  user.TEMPLATE = 'Template'
  user.TEMPLATE_NAME = 'Template Name'
  user.WORKFLOW = 'Workflow'
  user.WFT = 'WT'



  /*Roadblocks*/
  user.ROADBLOCK = `Blocked`
  user.ROADBLOCKS = `${userProperties.ROADBLOCKS}s`
  user.USERS_ROADBLOCKS = `${userProperties.USERS_ROADBLOCKS}`
  user.TO_BE_ASSIGN = `To Be Assigned`
  user.ASSIGNED = `Assigned`
  user.RESOLUTIONS = `Resolutions`
  user.BLOCKED = `Blocked`
  user.ROADBLOCK_DESCRIPTION = `${userProperties.ROADBLOCKS} Description`
  user.ASSIGNED_TO = `Assigned To`
  user.ASSIGNED_BY = `Assigned By`
  user.ASSIGNED_DATE = `Assigned Date`
  user.TARGET_DATE = `Target Date`
  user.COMBINE_TASKS = `Combine Tasks`
  user.ROADBLOCK_DATE = `${userProperties.ROADBLOCKS} Date`
  user.REQUESTED_BY = `Raised By`
  user.ROADBLOCK_PERCENTAGE = `${userProperties.ROADBLOCK_PERCENTAGE}`
  user.ROADBLOCK_STATUS = `Status`
  user.ROADBLOCK_LIST = `${userProperties.ROADBLOCKS} List`
  user.PENDING_ROADBLOCKS = `Pending ${userProperties.ROADBLOCKS}s`
  user.ASSIGN_ROADBLOCK = `Assign ${userProperties.ROADBLOCKS}`
  user.MODIFY_ROADBLOCK = `Modify ${userProperties.ROADBLOCKS}`
  user.PRIORITY = `Priority`
  user.SEVERITY = `Severity`
  user.MEETING_DATE=`Meeting Schedule Date`
  user.MEETING_TIME=`Meeting Schedule Time`



  /*Common Lables*/
  user.RELEASE_OWNER = `RO`
  user.ARCHIVE = `${userProperties.ARCHIVE}`
  user.EDIT = `Modify`
  user.VIEW_DETAILS = `View Details`
  user.ADD_TO_SPRINT = `Add to ${userProperties.MODULES}`
  user.ADD_TO_EPIC = `Add to ${userProperties.PROJECT}`
  user.MEMBERS = `Members`




  user.REASSIGN = `Reassign`
  user.CHANGE_STATUS = `Change Status`

  user.DELETE = `Delete`
  user.APPROVE = `Approve`
  user.VERIFY = `Verify`
  user.REJECT = `Reject`
  user.ACTION = `Action`
  user.ESTIMATEDTIME = `Estimation Time`
  user.DAYS = `Days`
  user.HOURS = `Hours`
  user.CHAT = `Chat`
  user.MESSAGES = `Messages`
  user.STATUS = `Status`
  user.ASSIGNED_ON = `Assigned On`
  user.CREATED_BY = `Created By`
  user.CREATED_DATE = `Created Date`
  user.TIME_LEFT = `Time Left`
  user.REPORTS = `${userProperties.AGILE_PROJECT_NAME} Reports`
  user.TIMESHEET = `Timesheet`
  user.MURAL_BOARD = `${userProperties.WHITEBOARD}`
  user.NEW_MURAL_BOARD = `${userProperties.WHITEBOARD}`
  user.SELECT_DATE_RANGE = `Select Date Range`

  /*Employees */
  user.EMPLOYEES = `${userProperties.AGILE_SQUAD}`

  /*Teams and Designation*/
  user.TEAM = `Department`
  user.DESIGNATION = `Title`
  user.TEAM_NAME = `Department Name`
  user.DESIGNATION_NAME = `Title Name`




  user.STORY_POINTS = `${userProperties.STORY_POINTS}`
  user.SELECT_SQUAD_MEMBER = user.GROUP_ENABLE === '1' ? `Select ${userProperties.SQUAD}/Group` : `Select ${userProperties.SQUAD}`
  user.MYTASKER_SELECT_SQUAD_MEMBER = user.GROUP_ENABLE === '1' ? `Select ${userProperties.SQUAD}/Group` : `Select ${userProperties.SQUAD}`
  user.ACCEPTANCE_CRITERIA = `${userProperties.ACCEPTANCE_CRITERIA}`
  user.DEFINITION_OF_DONE = `Definition of Done`
  user.PRIORITY_LEVEL = `${userProperties.PRIORITY_LEVEL}`
  user.AGILE_PROJECT_NAME = `${userProperties.AGILE_PROJECT_NAME}`
  user.ASSIGNMENT_ORDER = `Assignment Order`
  user.ASSIGNMENT_TO = `Assigned To`


  user.CONTRIBUTOR = `${userProperties.CONTRIBUTOR}`
  user.PRODUCT_OWNER = `${userProperties.PRODUCT_OWNER}`
  user.SCRUM_MASTER = `${userProperties.SCRUM_MASTER}`
  user.LIMITED_ACCESS_CONTRIBUTOR = `Limited Access Contributor`


user.SUPPORT_ROLE = `Support Role`
user.SUPPORT_MANAGER = `Support MANAGER`
user.L1 = `L1`
user.L2 = `L2`


user.START_DATE = `Start Date`
user.END_DATE = `End Date`



  user.ADD_SQUAD_MEMBER = `Add ${userProperties.SQUAD}`
  user.NEW_SQUAD_MEMBER = `New ${userProperties.SQUAD}`
  user.AGILE_SQUAD = `${userProperties.AGILE} ${userProperties.SQUAD}`
  user.SQUAD_MEMBER_NAME = `${userProperties.SQUAD} Name`
  user.USERNAME_EMAIL = `Username/Email`
  user.CONTACT = `Contact`
  user.ROLE = `Role`
  user.SHIFT_TIMINGS = `Shift Timings`
  user.WORKING_STATUS = `Working Status`
  user.AGILE_EDIT = `Edit`
  user.USER_STATUS = `User Status`
  user.AGILE = userProperties.AGILE


  // Reports
  user.PROJECT_NAME = `${userProperties.AGILE_PROJECT_NAME} Name`
  user.SPRINT_NAME = `${userProperties.MODULES} Name`
  user.ASSIGNED_STORY_POINTS = `Assigned ${userProperties.STORY_POINTS}`
  user.PENDING = `Pending`
  user.COMPLETED = `Completed`
  user.INDIVIDUAL_EMPLOYEE_REPORTS = `Individual Employee Reports`
  user.EMPLOYEE_NAME = `User Name`
  user.NO_OF_PROJECTS_INVOLVED = `No of Projects Involved`
  user.NO_OF_ACCOUNTS = `No of Accounts`
  user.PROJECTS_INVOLVED = `${userProperties.PROJECT}s Involved`
  user.DATE = `Date`
  user.SPRINT_VELOCITY = `${userProperties.MODULES} Velocity`
  user.TASK_VELOCITY = `Project Velocity`
  user.BURN_UP_REPORT = `${userProperties.BURN_UP_REPORT}`
  user.BURN_DOWN_REPORT = `${userProperties.BURN_DOWN_REPORT}`
  user.INDIVIDUAL_VELOCITY = `${userProperties.MAINTASKS} Velocity`
  user.EPIC_VELOCITY = `${userProperties.EPIC_VELOCITY}`
  user.INDIVIDUAL_WORKLOAD = `${userProperties.INDIVIDUAL_WORKLOAD}`
  user.SPRINT_CYCLE = `${userProperties.SPRINT_CYCLE}`

  user.PROFILE = ` Profile`
  user.LICENSE = `License`
  user.CHANGE_PASSWORD = `Change Password`
  user.LOGOUT = `Logout`
  user.SETTINGS = `Settings`
  user.SQUAD_MEMBER_ID = `${userProperties.SQUAD} ID`
  user.PAYEMENT = `Payment`
  user.USERNAME = `Username`
  user.FULLNAME = `Full Name`
  user.DISPLAY_NAME = `Display Name`
  user.EMAIL = `Email`
  user.MOBILE = `Mobile`
  user.WORKING_HOURS = `Working Hours`
  user.TOTAL_HOURS = `Total No of Hours Worked`
  user.TOTAL_HOURS_SPENT = `Total Hours Spent`

  user.TOTAL_USER_STORIES = `No of ${userProperties.USER_STORIES} Assigned`
  user.USER_EFFICIENCY = `${userProperties.USER_EFFICIENCY}`
  user.USER_EFFICIENCY_HOURS = "Utilization based on hours"
  user.USER_EFFICIENCY_STORY_POINTS = `Utilization based on ${userProperties.USER_STORIES}`
  user.ACCOUNT_STATUS = "Account Status"
  user.VERIFIED_ACCOUNT = "Account Verified"
  user.NOT_VERIFIED_ACCOUNT = "Not Verified"

  user.ALL_EMPLOYEE_REPORTS = `All Employee Reports`
  user.PROJECT_REPORTS = `${userProperties.AGILE_PROJECT_NAME} Time Report`
  user.TOTAL_USERS = 'No of Total Users'
  user.ACTIVE_USERS = 'No of Active Users'
  user.INACTIVE_USERS = `No of Inactive Users`
  user.WORKING_USERS = `No of Working Users`

  user.USAGE_REPORTS = `${userProperties.USAGE_REPORTS}`
  user.TOTAL_PROJECTS = `Total No of ${userProperties.AGILE_PROJECT_NAME}s`
  user.ACTIVE_PROJECTS = `No of Active ${userProperties.AGILE_PROJECT_NAME}s`
  user.AGILE_PROJECT_STATUS = `${userProperties.AGILE_PROJECT_NAME} Status`
  user.AGILE_PROJECT_REOPEN = `${userProperties.AGILE_PROJECT_NAME} Reopen`
  user.ARCHIVE_PROJECT = `Archive ${userProperties.AGILE_PROJECT_NAME}`
  user.LOGIN_TIME = `Last Login Date`
  user.REGISTERED_DATE = `Registered Date`
  // Image Icon Hovers
  user.COMMENTS = "Activity Log"
  user.ALL_USERS = "All Users"
  user.UPDATE_STORY = "Update User Story Progress"
  user.USER_STORY_PROGRESS = "User Story Progress"
  user.ACTION_ICON = `More`
  user.ATTACH_FILE = `Attach Image`

  // Group Chat
  user.CHAT_ROOM = 'Group Chat'
  user.CREATE_GROUP = 'Create Group'
  user.EDIT_GROUP = 'Edit Group'

  user.NEW_PROJECT = `New ${userProperties.AGILE_PROJECT_NAME}`
  user.SCRUM_BOARD = `${userProperties.SCRUM_BOARD}`


  user.IMG_SRC = `${userProperties.IMG_SRC}`

  user.CONSOLIDATED_TODO = "Team Tasker"
  user.TODO = "My Tasker"
  user.PRIVATE_TODO = "Personal Tasker"
  user.ADD_PRIVATE_TODO = "Add Task"
  user.ADD_TO_TODO_PROJECTS = `Task added to the project Successfully`

  /* Awards */
  user.AWARDS = "Kudos Points"
  user.ADD_AWARDS = "Award Kudos Points"
  user.AWARD_BY = "Awarded By"
  user.AWARD_TO = "Awarded To"
  user.DATE_OF_AWARD = "Date of Award"
  user.THANKS_REASON = "Kudos Reason"
  user.THANKS_DESCRIPTION = "Kudos Description"
  user.THANKS_POINTS = "Kudos Award"
  user.USERS_AWARDS = "Users Awards"
  user.THANKS_POINTS_SUMMARY = "Kudos POINTS SUMMARY"
  user.EMPLOYEE_EFFICIENCY_REPORT = "Employee Efficiency Report"
  user.EMPLOYEE_RANKING_REPORT = "Employee Ranking Report"

  // kanban board

  user.KANBAN = `${userProperties.KANBAN}` || "Kanban"
  user.KANBAN_NAME = `${userProperties.KANBAN_NAME}` || "supportboard"


  /* website props */
  user.APP_NAME = `${webproperties.APP_NAME}`
  user.SUPPORT_EMAIL = `${webproperties.SUPPORT_EMAIL}`
  user.CONTENT1 = `${webproperties.CONTENT1}`
  user.SUB_CONTENT1 = `${webproperties.SUB_CONTENT1}`
  user.IMAGE1 = "IMAGE1"
  user.IMAGE_CONTENT1 = `${webproperties.IMAGE_CONTENT1}`
  user.IMAGE_SUB_CONTENT1 = `${webproperties.IMAGE_SUB_CONTENT1}`
  user.IMAGE2 = "IMAGE2"
  user.IMAGE_CONTENT2 = `${webproperties.IMAGE_CONTENT2}`
  user.IMAGE_SUB_CONTENT2 = `${webproperties.IMAGE_SUB_CONTENT2}`
  user.IMAGE3 = "IMAGE3"
  user.IMAGE_CONTENT3 = `${webproperties.IMAGE_CONTENT3}`
  user.IMAGE_SUB_CONTENT3 = `${webproperties.IMAGE_SUB_CONTENT3}`
  user.IMAGE4 = "IMAGE4"
  user.IMAGE_CONTENT4 = `${webproperties.IMAGE_CONTENT4}`
  user.IMAGE_SUB_CONTENT4 = `${webproperties.IMAGE_SUB_CONTENT4}`
  user.IMAGE_SLIDER = "IMAGE_SLIDER"
  user.FOOTER_CONTENT1 = `${webproperties.FOOTER_CONTENT1}`
  user.FOOTER_SUB_CONTENT1 = `${webproperties.FOOTER_SUB_CONTENT1}`


  //Error Messages
  user.TASKS_WARNING = `${userProperties.MAINTASKS} contains ${userProperties.SUBTASKS}s, Please complete the ${userProperties.SUBTASKS}s before moving the ${userProperties.MAINTASKS} to done`
  user.ROADBLOCK_WARNING = `${userProperties.MAINTASKS} contains ${userProperties.ROADBLOCKS}s, Please complete the ${userProperties.ROADBLOCKS}s before moving the ${userProperties.MAINTASKS} to done`

  // Accounts Information
  user.ACCOUNTS = 'Accounts'
  user.ACCOUNTS_STATUS = 'Account Status'
  user.CLIENT_NAME = 'Client Name'
  user.NO_OF_PROJECTS = `No of ${userProperties.AGILE_PROJECT_NAME}s`
  user.NO_OF_USERS = 'No of Users'
  user.role_array =
    { "Contributor": `${userProperties.CONTRIBUTOR}`, "Product Owner": `${userProperties.PRODUCT_OWNER}`, "Scrum Master": `${userProperties.SCRUM_MASTER}`, "Limited Access Contributor": 'Limited Access Contributor' }

/*Version and Date*/
user.VERSION = `Version 4.6.6`
user.VERSION_DATE = `09-18-2024`

  user.URL = Client_URL;
  user.BaseUrl = Server_URL;
  user.CHATAPI = Server_URL;

  // console.log(user);
  return (<UserContext.Provider value={user} {...props} />);
};

export const useWebProperties = () => useContext(UserContext);
